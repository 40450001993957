import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import JsLogo from "../../images/Careers/deeplai-career-javascript-developer.svg";

const CareersJavascriptDevelopers = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.javascript) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (

        <OfferPage
            image={JsLogo}
            imageAlt={'deeplai career javascript developer'}
            name={routes.CareersJavascriptDevelopers.pageTitle}
            scopeOfDuties={<><p>software implementation by specification</p><p>active participation in
                the creation of the concept and design of software</p><p>cooperation with other teams -
                testers, designers, etc ...</p><p>cooperation in implementing and maintaining
                application with customers</p></>}
            ourRequirements={<><p>good knowledge of JavaScript language and React framework – mid-level
                (at least)</p><p>good knowledge of HTML and CSS</p><p>knowledge of Git, TypeScript, ECMA standards are nicely welcome</p>
                <p>master degree (or still studying) in IT field</p><p>basic knowledge of English to
                    work with technical documentation</p></>}
        />
    );
};

export default CareersJavascriptDevelopers;
